// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$bootstrap_info_color: #17a2b8;

//coupons
$inactive_coupon_bkg: #ff5757;
$active_coupon_bkg: #9be7ac;
$ahead_coupon_bkg: #dff9ff;

//cookie
$cookie_module_background: #17a2b8;
//$cookie_btn_color: #ff5757;
