// Variables
@import 'admin/variables';

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback');

@import "admin/font-awesome-all.min.css";
@import "admin/icheck-bootstrap.min.css";
@import "admin/adminlte.min.css";
@import "admin/daterangepicker.css";
@import "admin/OverlayScrollbars.min.css";
@import "admin/summernote-bs4.min.css";
@import "admin/select2.min.css";
@import "admin/select2-bootstrap4.min.css";
@import "admin/jquery.datetimepicker.min.css";


body{
    .login-box{
        width: 360px
    }
    &.login-page{
        background-color: #0A7DB6;
        background-image: linear-gradient(180deg, #0A7DB6 0%, #00AEEF 100%);
        .login-logo{
            a{
                color: #fff;
            }
        }
    }
}

.user-panel{
  .info{
      color: #fff;
  }
}

.nav-sidebar{
    .nav-header{
        text-transform: uppercase;
    }
}


//CSS Sortable menu creation blocks
.main-sortable ul li.sortable-item {
    padding-right: 0;
}

//Modal
#myModal {
    &.modal-lg {
        max-width: 100%;
        .modal-dialog { max-width: 800px;}
    }

}

//Select2 to hide selected option from dropdown
.select2-results__option[aria-selected=true] {
    display: none;
}
.select2-selection__rendered {
    .select2-selection__choice {
        background-color: $bootstrap_info_color !important;
    }
    .select2-selection__choice__remove{
        color:white !important;
    }
}
//Product gallery
#product-files {
    tr:first-child {
        td {
            border-top: none !important;
        }
    }

    tr:last-child {
        td {
            border-bottom: none !important;
        }
    }

    .figure {
        .set-main {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .img-thumbnail {
        max-height: 140px !important;
        width: auto !important;
    }
}
//Product list
#products-list {
    .img-thumbnail {
        max-height: 70px !important;
        width: auto !important;
    }
}
.no-border-t{ td { border-top: none !important;} }
.no-border-b{ td {border-bottom: none !important;} }
//Product Attributes section
#product-attributes{
    .badge {
        font-size:18px;
        position: relative;

        .remove-value{
            font-size:80%;
            color:#000;
            cursor: pointer;
            position: absolute;
            top:-6px;
            right:-8px;

            &:hover{

            }
        }
    }
}

.qty-number-edit {
    width: 100px;
    margin-right: 14px;
}
.value-edit {
    padding-left: 20px;
}
.qty-edit-action {
    padding-left: 20px;
    float: right;
}

//Coupons
table#coupons-list,
table#carrier-list{
    tr td {
        &.inactive {
            background-color: $inactive_coupon_bkg;
            color: #fff;
        }
        &.active {
            background-color: $active_coupon_bkg;
        }
        &.ahead{
            background-color: $ahead_coupon_bkg;
        }
    }
}

//cookie module
#cookie-module {
    background-color: #17a2b8;
    color: #fff;
    display: flex;
    position: fixed;
    bottom: 0;
    justify-content: space-between;
    padding: 15px 20px;
    width: 100%;
    z-index: 10000;
}
#cookie-module a {
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    padding: 5px;
}
//#cookie-module #agrre-btn {
    //background-color: $cookie_btn_color;
    //color: #fff;
    //height: auto !important;
    //padding: 0 10px;;
//}

//blink card
.card{
    &.collapsed-card {
        &.blink-bg {
            .card-header{
                color: #fff;
                padding: 10px;
                display: inline-block;
                border-radius: 5px;
                animation: blinkingBackground 1s infinite;
            }
            @keyframes blinkingBackground{
                0%		{ background-color: #fff; color: #000;}
                50%	    { background-color: #dc3545; color: #fff;}
                100%	{ background-color: #fff; color: #000;}
            }
        }
    }
}

//Documentation
#documentation {
    img.img-thumbnail {
        margin: 10px 0 20px 0;
    }

    > div {
        > p, > img, > div {
            margin-left: 30px !important;
        }
    }
    h3{
        margin-top: 30px;
        margin-bottom: 30px;
        color: #666666;
        padding-left: 10px;
        border-left: 9px solid $bootstrap_info_color;
    }
}
@media screen and (min-width: 1255px){
    #documentation .accordion .card-body {
        padding-left: 50px !important;
    }
    #documentation .accordion .card-body > p
    , #documentation .accordion .card-body > .row
    //, #documentation .accordion .card-body > img
    {
        margin-left: 30px !important;
    }
}


